import React, { Component } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from './core/AsyncComponent';

//pages
import LoginPage from './components/login/LoginPage';

// Bulma CSS framework
// import 'bulma/css/bulma.min.css';

// Sentry
import ErrorMonitoring from './utils/ErrorMonitoring';
(global as any).EM = ErrorMonitoring;

// To use code-splitting, import the page using this syntax:
// const AsyncExamplePage = asyncComponent(() => import('./pages/Example.jsx'));
// then use it below:
// <Route path="/example" component={AsyncExamplePage} />

const AsyncHomePage = asyncComponent(() => import('./pages/home/Home'));

class App extends Component {
  render() {
    return (
      <div className="container">
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/" component={AsyncHomePage} />
        </Switch>
      </div>
    );
  }
}

export default App;
