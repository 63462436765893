import React from 'react';

import { Paragraph, TextColour } from '../common/text';
import { Button, ColourScheme, TextInput } from '../common/forms';
import { InputType } from '../common/forms/TextInput';
import Alert, { AlertType } from '../common/alerts';

type Props = {};

type State = {
  username: string;
  password: string;
  errorMessage: string;
  isLoading: boolean;
};

class LoginForm extends React.Component<Props, State> {
  userIDInput: HTMLInputElement | null | undefined;
  passwordInput: HTMLInputElement | null | undefined;

  state: State = {
    errorMessage: '',
    username: '',
    password: '',
    isLoading: false,
  };

  onInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      this.onClickLogin();
    }
  };

  onClickLogin = () => {
    const userID = this.state.username;
    const password = this.state.password;
    if (!userID || !password) {
      this.setState({
        errorMessage: 'Type your username and password, then click Log in.',
      });
      return;
    }

    this.setState({
      isLoading: true,
    });

    (global as any).AuthUtils.login(userID, password)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error: any) => {
        const errorMessage = error.message
          ? `${error.message} (${error.code})`
          : 'Could not log you in. Try again later';
        this.setState({ errorMessage, isLoading: false });
      });
  };

  _renderErrorMessage() {
    if (!this.state.errorMessage) {
      return null;
    }

    return (
      <div data-uk-alert className="uk-alert-danger">
        {this.state.errorMessage}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Paragraph colour={TextColour.PrimaryLight}>
          To use Mews, you'll need to log in.
        </Paragraph>
        <TextInput
          autoComplete="username"
          colourScheme={ColourScheme.Dark}
          focusOnLoad={true}
          label="Username"
          onChange={(username) => {
            this.setState({
              username,
            });
          }}
          value={this.state.username}
        />

        <TextInput
          autoComplete="current-password"
          colourScheme={ColourScheme.Dark}
          label="Password"
          onChange={(password) => {
            this.setState({
              password,
            });
          }}
          onHitEnter={this.onClickLogin}
          type={InputType.Password}
          value={this.state.password}
        />

        <div style={{ textAlign: 'right' }}>
          <Button
            colourScheme={ColourScheme.Dark}
            label="Log in"
            onClick={this.onClickLogin}
            primary={true}
            showSpinner={this.state.isLoading}
          />
        </div>

        {this.state.errorMessage && (
          <Alert type={AlertType.Error} text={this.state.errorMessage} />
        )}
      </div>
    );
  }
}

export default LoginForm;
