import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';

import './_bindings';
import AuthUtils from './utils/AuthUtils';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';

const client = new ApolloClient({
  uri: '/dragon/x/gql',
  headers: {
    Authorization: AuthUtils.getLoginSession()
      ? AuthUtils.getLoginSession().token
      : null,
  },
});

(global as any).AuthUtils = AuthUtils;

const GQLApp = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

ReactDOM.render(<GQLApp />, document.getElementById('root'));
registerServiceWorker();
