import * as React from 'react';
import classnames from 'classnames';

import styles from './Paragraph.module.css';

enum TextColour {
  PrimaryDark,
  PrimaryLight,
  Secondary,
}

enum TextSize {
  Small,
  Normal,
  Large,
}

type Props = {
  children: React.ReactNode;
  colour?: TextColour;
  size?: TextSize;
};

function Paragraph(props: Props): JSX.Element {
  return (
    <div
      className={classnames({
        [styles.para]: true,
        [styles.primary_dark]: props.colour === TextColour.PrimaryDark,
        [styles.primary_light]: props.colour === TextColour.PrimaryLight,
        [styles.secondary]: props.colour === TextColour.Secondary,
        [styles.small]: props.size === TextSize.Small,
        [styles.normal]: props.size === TextSize.Normal,
        [styles.large]: props.size === TextSize.Large,
      })}
    >
      {props.children}
    </div>
  );
}

Paragraph.defaultProps = {
  colour: TextColour.PrimaryDark,
  size: TextSize.Normal,
};

export { TextColour, TextSize };
export default Paragraph;
