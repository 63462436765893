import cryptojs from 'crypto-js';
import PreLoginEndpoint from '../endpoints/authentication/PreLoginEndpoint';
import LoginEndpoint from '../endpoints/authentication/LoginEndpoint';

const SESSION_STORAGE_KEY = 'dragon.ua.55823357ab397f0ac39f7daea36fba03';

const AuthUtils = {
  getLoginSession() {
    return JSON.parse(
      (window as any).localStorage.getItem(SESSION_STORAGE_KEY)
    );
  },

  setLoginSession(session: any) {
    window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
  },

  updateDisplayName(name: string) {
    const session = this.getLoginSession();
    session.displayName = name;
    this.setLoginSession(session);
  },

  clearLoginSession() {
    window.localStorage.removeItem(SESSION_STORAGE_KEY);
    window.location.href = '/login';
  },

  isLoggedIn() {
    return !!this.getLoginSession();
  },

  login(userid: string, password: string) {
    return new Promise((resolve, reject) => {
      const passwordHash = cryptojs.SHA256(password).toString();
      PreLoginEndpoint.call({ userid })
        .then((preLoginResult) => {
          return LoginEndpoint.call({
            token: preLoginResult.token,
            passwordHash: cryptojs
              .SHA256(preLoginResult.salt + passwordHash)
              .toString(),
          });
        })
        .then((loginResult) => {
          if (!loginResult.success) {
            reject(loginResult.error);
            return;
          }

          this.setLoginSession(loginResult.session);
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  logout() {
    this.setLoginSession(null);
  },
};

export default AuthUtils;
