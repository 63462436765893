import * as React from 'react';
import classnames from 'classnames';

import FormField, { ColourScheme } from './FormField';

import styles from './TextInput.module.css';

const { useEffect } = React;

enum InputType {
  Text = 'text',
  Password = 'password',
}

type Props = {
  autoComplete?: string;
  colourScheme?: ColourScheme;
  disabled?: boolean;
  focusOnLoad?: boolean;
  helpText?: string;
  label?: string;
  onChange: (value: string) => void;
  onHitEnter?: () => void;
  placeholder?: string;
  prefix?: React.ReactNode;
  type?: InputType;
  value: string;
};

function TextInput(props: Props): JSX.Element {
  let textInput: HTMLInputElement | null = null;

  useEffect(() => {
    if (!!props.focusOnLoad && !!textInput && !props.value) {
      textInput.focus();
    }
  }, [textInput, props.value, props.focusOnLoad]);

  return (
    <FormField
      colourScheme={props.colourScheme}
      label={props.label}
      helpText={props.helpText}
    >
      <div className={styles.container}>
        {props.prefix && <div className={styles.prefix}>{props.prefix}</div>}
        <input
          autoComplete={props.autoComplete}
          className={classnames({
            [styles.text_input]: true,
            [styles.disabled]: props.disabled,
          })}
          disabled={props.disabled}
          type={props.type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.value);
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!props.onHitEnter) {
              return;
            }

            if (e.keyCode === 13) {
              props.onHitEnter();
            }
          }}
          placeholder={props.placeholder}
          ref={(input) => {
            textInput = input;
          }}
          value={props.value}
        />
      </div>
    </FormField>
  );
}

TextInput.defaultProps = {
  colourScheme: ColourScheme.Light,
  disabled: false,
  placeholder: '',
  type: InputType.Text,
};

export { InputType };
export default TextInput;
