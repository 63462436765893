import * as React from 'react';
import classnames from 'classnames';
import FormField, { ColourScheme } from './FormField';

import styles from './TextArea.module.css';

const { useEffect } = React;

type Props = {
  autoComplete?: string;
  colourScheme?: ColourScheme;
  disabled?: boolean;
  focusOnLoad?: boolean;
  helpText?: string;
  label?: string;
  showLineNumbers: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  rows: number;
  value: string;
};

function TextArea(props: Props): JSX.Element {
  let textareaElement: HTMLTextAreaElement | null = null;

  useEffect(() => {
    if (!!props.focusOnLoad && !!textareaElement && !props.value) {
      textareaElement.focus();
    }
  }, [textareaElement, props.value, props.focusOnLoad]);

  return (
    <FormField
      colourScheme={props.colourScheme}
      label={props.label}
      helpText={props.helpText}
    >
      <div className={styles.container}>
        {props.showLineNumbers && (
          <div className={styles.line_numbers}>
            {props.value.split('\n').map((_, i) => (
              <div key={i}>{i + 1}</div>
            ))}
          </div>
        )}

        <textarea
          className={classnames({
            [styles.textarea]: true,
          })}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.currentTarget.value);
          }}
          placeholder={props.placeholder}
          ref={(element) => (textareaElement = element)}
          rows={props.showLineNumbers ? 0 : props.rows}
        />
      </div>
    </FormField>
  );
}

TextArea.defaultProps = {
  colourScheme: ColourScheme.Light,
  disabled: false,
  placeholder: '',
  rows: 10,
  showLineNumbers: false,
};

export default TextArea;
