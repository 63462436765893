import * as Sentry from '@sentry/browser';

export type LogMetadata = {
  tags?: {
    [key: string]: string;
  };
  component?: string;
  graphQLQuery?: string;
  extraData?: {
    [key: string]: string;
  };
};

export default {
  _isEnabled: false,

  init(): void {
    if (!(global as any).__em_dsn) {
      return;
    }

    Sentry.init({
      dsn: (global as any).__em_dsn,
    });
    this._isEnabled = true;
  },

  _setMetadata(scope: any, metadata?: LogMetadata): void {
    scope.setExtra('client_version', (global as any).app_info.version);
    scope.setExtra('build', (global as any).app_info.build);
    scope.setTag('layer', 'Client');

    const session = (global as any).AuthUtils.getLoginSession();
    scope.setUser({
      id: !!session ? session.uid : 'unknown',
    });

    if (!metadata) {
      return;
    }

    if (!!metadata.tags) {
      Object.keys(metadata.tags).forEach((tag) => {
        const val = metadata && metadata.tags && metadata.tags[tag];
        scope.setTag(tag, val);
      });
    }
    if (!!metadata.component) {
      scope.setExtra('component', metadata.component);
    }
    if (!!metadata.graphQLQuery) {
      scope.setExtra('graphQLQuery', metadata.graphQLQuery);
    }
    if (!!metadata.extraData) {
      Object.keys(metadata.extraData).forEach((key: string) => {
        const val = metadata && metadata.extraData && metadata.extraData[key];
        scope.setExtra(key, val);
      });
    }
  },

  logException(e: Error, metadata?: LogMetadata): void {
    Sentry.withScope((scope) => {
      this._setMetadata(scope, metadata);

      Sentry.captureException(e);
    });
  },

  logExceptionAsync(e: Error, metadata?: LogMetadata): Promise<string> {
    return new Promise((resolve) => {
      Sentry.withScope((scope) => {
        this._setMetadata(scope, metadata);

        const eventID = Sentry.captureException(e);
        resolve(eventID);
      });
    });
  },

  logMessageAsync(m: string, metadata?: LogMetadata): Promise<string> {
    return new Promise((resolve) => {
      Sentry.withScope((scope) => {
        this._setMetadata(scope, metadata);

        const eventID = Sentry.captureMessage(m);
        resolve(eventID);
      });
    });
  },

  showReportDialog(eventId?: string): void {
    Sentry.showReportDialog({
      eventId,
    });
  },
};
