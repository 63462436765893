import * as React from 'react';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import styles from './Alerts.module.css';

enum AlertType {
  Info,
  Success,
  Error,
  Warning,
}

type Props = {
  text: string;
  type: AlertType;
};

const AlertIcon: { [key in AlertType]: JSX.Element } = {
  [AlertType.Info]: <FontAwesomeIcon icon={faInfoCircle} />,
  [AlertType.Success]: <FontAwesomeIcon icon={faCheckCircle} />,
  [AlertType.Error]: <FontAwesomeIcon icon={faExclamationCircle} />,
  [AlertType.Warning]: <FontAwesomeIcon icon={faExclamationTriangle} />,
};

function Alert(props: Props): JSX.Element {
  return (
    <div
      className={classnames({
        [styles.alert]: true,
        [styles.info]: props.type === AlertType.Info,
        [styles.success]: props.type === AlertType.Success,
        [styles.error]: props.type === AlertType.Error,
        [styles.warning]: props.type === AlertType.Warning,
      })}
    >
      <div className={styles.icon}>{AlertIcon[props.type]}</div>
      <div className={styles.content}>{props.text}</div>
    </div>
  );
}

export { AlertType };
export default Alert;
