import { EventEmitter } from 'events';

export enum PreferencesKey {
  AppColourScheme = 'mews.colour_scheme',
  DebugMode = 'mews.debugmode',
  MuteNotifications = 'mews.mute',
}

class DevicePreferencesUtils extends EventEmitter {
  private _cache: { [key: string]: string } = {};

  getPreference(key: PreferencesKey, defaultValue: string): string {
    if (!!this._cache[key]) {
      return this._cache[key];
    }

    const saved = localStorage.getItem(key.toString());
    if (!!saved) {
      this._cache[key] = saved;
    }

    return saved || defaultValue;
  }

  savePreference(key: PreferencesKey, value: string) {
    localStorage.setItem(key.toString(), value);
    this._cache[key] = value;
    this.emit(key);
  }

  addChangeListener(key: PreferencesKey, handler: () => void) {
    this.on(key, handler);
  }

  removeChangeListener(key: PreferencesKey, handler: () => void) {
    this.removeListener(key, handler);
  }
}

/**
 * A utility class for reading preferences outside the main app context. This is used in core UI components.
 */
export const DevicePreferencesReader = {
  getPreference(key: PreferencesKey, defaultValue: string): string {
    return localStorage.getItem(key.toString()) || defaultValue;
  },
};

export default DevicePreferencesUtils;
