// @source_hash=9f6d8453b2098e66b0b4612d00a093e4
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/PreLoginEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/PreLoginEndpoint.js`.
// Generated on Sat Apr 11 2020 12:01:57 GMT+0800 (+08)

export interface PreLoginEndpointParams {
  userid: string;
};

export interface PreLoginEndpointResult {
  token: string;
  salt: string;
};

export default class PreLoginEndpoint {
  static call(params: PreLoginEndpointParams): Promise<PreLoginEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/prelogin", type: "get", params: [] },
      params,
    );
  }
}
