// @source_hash=f0d521161520a4a75d6d4b7421aee5f8
// This is an autogenerated file.
// To make changes, edit app/server/endpoints/authentication/LoginEndpoint.js,
// then run `dragon gen-endpoint app/server/endpoints/authentication/LoginEndpoint.js`.
// Generated on Sat May 16 2020 12:18:11 GMT+0800 (Singapore Standard Time)

export interface LoginEndpointParams {
  token: string;
  passwordHash: string;
};

export interface LoginEndpointResult {
  success: boolean;
  session?: {
	  token: string,
	  displayName: string,
	  uid: string,
	  roles: Array<string>,
	  privateKey?: string,
	} | null;
  error?: {
	  code: string,
	  message: string,
	} | null;
};

export default class LoginEndpoint {
  static call(params: LoginEndpointParams): Promise<LoginEndpointResult> {
    return (global as any).API.call(
      { url: "/api/auth/login", type: "post", params: [] },
      params,
    );
  }
}
