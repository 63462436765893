// @ts-nocheck
// From https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html

import React, { Component } from 'react';
import Spinner from '../components/common/Spinner';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? (
        <C {...this.props} />
      ) : (
        <div style={{ marginTop: '3rem' }}>
          <Spinner />
        </div>
      );
    }
  }

  return AsyncComponent;
}
