import * as React from 'react';
import classnames from 'classnames';
import {
  DevicePreferencesReader,
  PreferencesKey,
} from '../../../utils/DevicePreferences';
import Spinner from '../Spinner';

import { ColourScheme } from './FormField';

import styles from './Button.module.css';

type Props = {
  colourScheme?: ColourScheme;
  disabled?: boolean;
  label: React.ReactNode;
  onClick: () => void;
  primary?: boolean;
  showSpinner?: boolean;
  tooltip?: string;
  tooltipDirection?:
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'up-left'
    | 'up-right'
    | 'down-left'
    | 'down-right';
};

function Button(props: Props): JSX.Element {
  return (
    <button
      className={classnames({
        [styles.light]: props.colourScheme === ColourScheme.Light,
        [styles.dark]: props.colourScheme === ColourScheme.Dark,
        [styles.button]: true,
        [styles.primary]: props.primary,
        [styles.disabled]: props.disabled || props.showSpinner,
      })}
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label={props.tooltip}
      data-balloon-pos={props.tooltipDirection}
    >
      {props.label}
      {props.showSpinner && (
        <Spinner
          display="button"
          colourScheme={
            props.colourScheme === ColourScheme.Dark ? 'dark' : 'light'
          }
        />
      )}
    </button>
  );
}

Button.defaultProps = {
  colourScheme:
    DevicePreferencesReader.getPreference(
      PreferencesKey.AppColourScheme,
      'light'
    ) === 'light'
      ? ColourScheme.Light
      : ColourScheme.Dark,
  disabled: false,
  primary: false,
  showSpinner: false,
  tooltipDirection: 'up',
};

export default Button;
