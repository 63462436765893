/** AUTO-GENERATED CODE - DO NOT MODIFY **/
global._API_HOST = "/dragon";
global.app_info = {"name":"mews-server","version":"3.7.0","build":"Build_20220802.1316"};
global.API = { call: function (endpoint, data) {
        const method = endpoint.type.toUpperCase();
        let url = (global._API_HOST || '') + endpoint.url;
        endpoint.params.forEach(function (key) {
            url = url.replace(':' + key, data[key]);
            data[key] = undefined;
        });
        let body = data;
        if (method === 'GET' && !!data) {
            body = null;
            let queryParams = Object.keys(data)
                .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
                .join('&');
            url += `?${queryParams}`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: !!global.AuthUtils.getLoginSession()
                        ? global.AuthUtils.getLoginSession().token
                        : '',
                },
                mode: 'cors',
                body: !!body ? JSON.stringify(body) : null,
            })
                .then((result) => result.json())
                .then((result) => resolve(result))
                .catch((error) => reject(error));
        });
    },  };
global.UserRoles = {"USER":"u","LOCKED":"l","RESTRICTED":"r","TESTER":"t","ADMIN":"a"};
global.__em_dsn = undefined;
global.EM && global.EM.init();global.GraphTypes = {"User":{"typeID":1074,"fields":["username","email_address","password_hash","display_name","last_active_timestamp","roles","private_key","public_key"],"edges":["contacts"],"config":{"create":true,"update":true,"delete":true}},"Conversation":{"typeID":8496,"fields":["title","description","secret","type","is_archived"],"edges":["participants"],"config":{"create":true,"update":true,"delete":true}},"ChatCommand":{"typeID":6739,"fields":["name","command","description","user_action","is_hidden","integration"],"edges":[],"config":{"create":true,"update":true,"delete":true}},"Integration":{"typeID":7116,"fields":["name","secret","webhook_url"],"edges":["commands"],"config":{"create":true,"update":true,"delete":true}},"SiteConfigEntry":{"typeID":12605,"fields":["key","value","description","is_public"],"edges":[],"config":{"create":true,"update":true,"delete":true}}}
