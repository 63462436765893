import React from 'react';

import './Spinner.css';

export type SpinnerDisplayMode = 'block' | 'inline' | 'button';

type Props = {
  display: SpinnerDisplayMode;
  colourScheme: 'light' | 'dark';
};

class Spinner extends React.Component<Props> {
  static defaultProps = {
    display: 'block',
    colourScheme: 'light',
  };

  render() {
    return (
      <div
        className={`spinner ${this.props.display} ${
          this.props.colourScheme === 'dark' ? 'dark' : ''
        }`}
      />
    );
  }
}

export default Spinner;
