import React from 'react';

import LoginForm from './LoginForm';

import mews_logotype from './mews_logotype.png';
import mews_logo from './mews_v3_logo_1.png';

import styles from './LoginPage.module.css';

class LoginPage extends React.Component {
  render() {
    return (
      <div className={styles.page_content}>
        <div className={styles.login_card}>
          <img src={mews_logotype} alt="mews" className={styles.logotype} />
          <div className={styles.login_content}>
            <LoginForm />
          </div>
          <img src={mews_logo} alt="mews" className={styles.logo} />
        </div>
      </div>
    );
  }
}

export default LoginPage;
