import * as React from 'react';
import classnames from 'classnames';

import styles from './FormField.module.css';

enum ColourScheme {
  Dark,
  Light,
}

type Props = {
  colourScheme?: ColourScheme;
  label?: string;
  children: JSX.Element;
  helpText?: string;
};

export { ColourScheme };
function FormField(props: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.label_container}>
        {props.label && (
          <div
            className={classnames({
              [styles.label]: true,
              [styles.dark]: props.colourScheme === ColourScheme.Dark,
              [styles.light]: props.colourScheme === ColourScheme.Light,
            })}
          >
            {props.label}
            {props.helpText && (
              <span className={styles.help_text}>
                {' '}
                &middot; {props.helpText}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}

FormField.defaultProps = {
  colourScheme: ColourScheme.Light,
};

export default FormField;
